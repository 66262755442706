import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    clientId: {
      type: Number,
      default: null
    },
    theFranchiseClient: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    franchiseClientData: {},
    bgVariantHeader: 'dark',
    textVariantHeader: 'white'
  }),
  computed: {
    ...mapGetters([
      'franchiseClient',
      'clientContacts'
    ]),
    clientData () {
      return this.franchiseClientData.client || {}
    },
    clientName () {
      if (this.clientData) {
        return `${this.clientData.fname} ${this.clientData.lname}`
      }
      return ''
    },
    clientAddress () {
      if (this.clientData) {
        return `${this.clientData.street1} ${this.clientData.street2}
        ${this.clientData.city}, ${this.clientData.state} ${this.clientData.zipCode}`
      }
      return ''
    },
    googleClientAddress () {
      return this.clientAddress
        .replace(/\n/g, ' ')
        .replace(/\s\s+/g, ' ')
        .replace(/\s/g, '+')
        .replace(/[^a-z0-9+]/ig, '')
    },
    clientContactInfo () {
      let contacts = ''
      for (let contact in this.clientContacts) {
        if (contact.data) {
          contacts = contact.data.relationship
        }
      }
      return contacts
    },
  },
  async mounted () {
    if (this.clientId) {
      this.franchiseClientData = await this.$store.dispach('getFranchiseClient', {
        franchiseCode: null,
        clientId: this.clientId
      })
    } else if (this.theFranchiseClient) {
      this.franchiseClientData = this.theFranchiseClient
    } else if (this.franchiseClient.id) {
      this.franchiseClientData = this.franchiseClient
    }
  },
  methods: {
    ...mapActions(['getContactRecords', 'resetContactRecords'])
  }
}