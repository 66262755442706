<template>
  <b-card
    v-if="clientData.id"
    header-tag="header"
    :header-bg-variant="bgVariantHeader"
    :header-text-variant="textVariantHeader"
    footer-tag="footer"
    no-body
  >
    <template v-if="!noHeader" #header>Associated Tutors</template>
    <b-list-group flush>
      <b-list-group-item v-for="emp in clientData.employees" class="flex-column align-items-start" :key="emp.id">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ emp.emp.profile.fname }} {{ emp.emp.profile.lname }}</h5>
          
          <b-button size="sm" variant="warning">-</b-button>
        </div>

        <small>General Math, Fractions</small>
      </b-list-group-item>
      <b-list-group-item href="#" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Associate a Tutor</h5>
        </div>
      </b-list-group-item>
    </b-list-group>
    <template v-if="!noFooter" #footer>Some Footer Actions...</template>
  </b-card>
</template>

<script>
import mixins from './clientProfileMixins'

export default {
  props: {
    noHeader: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    }
  },
  name: 'ClientEmployeesCard',
  mixins : [mixins],
  computed: {}
}
</script>

<style lang="scss">
address {
  @extend .py-2 !optional
}
</style>
