<template>
  <b-card
    v-if="clientData.id"
    header-tag="header"
    :header-bg-variant="bgVariantHeader"
    :header-text-variant="textVariantHeader"
    footer-tag="footer"
  >
    <template v-if="!noHeader" #header>Contact Info</template>
    <div v-if="loading"><BaseLoading /></div>
    <div v-else-if="clientContacts.length">
      <div v-html="clientContactInfo"></div>
      <div v-for="contact in clientContacts" :key="contact.id">
        {{ contact.data.relationship }}: {{ contact.data.fname }} {{ contact.data.lname
        }}<br />
        Phone: {{ contact.phones[0] ? contact.phones[0].number : clientData.phone }}<br />
        email: {{ contact.data.email || clientData.email }}
        <a :href="'mailto:' + (contact.data.email || clientData.email)"
          ><b-icon icon="envelope" aria-hidden="true"></b-icon
        ></a>
      </div>
    </div>
    <p v-else>
      Phone: {{ clientData.phone }}<br />
      email: {{ clientData.email }}
    </p>
    <address class="my-2">
      <span v-html="$options.filters.nl2br(clientAddress)"></span>
      <a
        :href="'https://www.google.com/maps/place/' + googleClientAddress"
        target="_blank"
        ><b-icon icon="link45deg" aria-hidden="true"></b-icon
      ></a>
    </address>
    <template v-if="!noFooter" #footer>Some Footer Actions...</template>
  </b-card>
</template>

<script>
import clientProfileMixins from './clientProfileMixins'
import { constants } from '@/shared/constants'

export default {
  props: {
    noHeader: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
  },
  name: 'CardClientContactDetails',
  data: () => ({
    loading: true,
  }),
  mixins: [clientProfileMixins],
  async mounted() {
    let params = {}
    // associated group?
    const clientHousehold = this.clientData.groups.find(
      (g) => g.group.type.id === constants.GROUP_ID_HOUSEHOLD
    )
    if (clientHousehold) {
      // get group contacts
      params.groupId = clientHousehold.group.id
    }
    params.clientId = this.clientData.id
    await this.getContactRecords(params)
    this.loading = false
  },
  destroyed() {
    this.resetContactRecords()
  },
}
</script>

<style lang="scss">
address {
  @extend .py-2 !optional;
}
</style>
