<template>
  <div style="position: relative">
    <!-- CLIENT LIST -->
    <data-table
      :uri="uri"
      :uriParams="uriParams"
      :fields="fields"
      :customItems="customItems"
      :isSelectable="true"
      no-filter
    >
      <template #headerFilters>
        <b-row>
          <b-col cols="12" lg="3">
            <!-- b-row no-gutters>
              <b-col
                ><b-form-input
                  v-model="fname"
                  placeholder="First Name"
                  debounce="500"
                ></b-form-input
              ></b-col>
              <b-col
                ><b-form-input
                  v-model="lname"
                  placeholder="Last Name"
                  debounce="500"
                ></b-form-input
              ></b-col>
            </b-row -->
            <BaseFormClient v-model="client" no-label />
          </b-col>
          <b-col cols="6" lg="3">
            <b-input-group prepend="Status">
              <b-form-select
                v-model="status"
                :options="clientStatusOptions"
                value-field="id"
                text-field="label"
              >
                <template #first>
                  <b-form-select-option :value="null">All</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-col>
          <b-col cols="6" lg="2">
            <b-form-input
              v-model="zipCode"
              placeholder="Zip Code"
              debounce="500"
            ></b-form-input>
          </b-col>
          <b-col v-if="!$store.getters.currentFranchiseCode" cols="12" lg="4">
            <BaseFormFranchise @selected-code="franchiseCode = $event" no-label />
          </b-col>
        </b-row>
      </template>

      <!-- ACTIONS -->
      <template #cell(action)="data">
        <b-dropdown
          size="sm"
          id="franchise-action"
          text="Action"
          class="d-print-none"
          block
        >
          <b-dropdown-item @click="onViewClientProfile(data.item)"
            ><b-icon icon="eye" aria-hidden="true"></b-icon> View</b-dropdown-item
          >
          <b-dropdown-item @click="onNewAppt('clientVisit', data.item.client)"
            ><b-icon icon="plus" aria-hidden="true"></b-icon> New Session</b-dropdown-item
          >
          <b-dropdown-item @click="$bvModal.msgBoxOk('Will open in new page')"
            ><b-icon icon="folder2-open" aria-hidden="true"></b-icon>
            Open</b-dropdown-item
          >
          <b-dropdown-item
            v-if="data.item.client"
            :to="{ name: 'ClientForm', query: { cid: data.item.client.id } }"
            ><b-icon icon="pencil" aria-hidden="true"></b-icon> Edit</b-dropdown-item
          >
        </b-dropdown>
      </template>

      <!-- GROUPS -->
      <template #cell(clientGroups)="data">
        <b-btn
          v-if="
            data.item.client && data.item.client.groups && data.item.client.groups.length
          "
          size="sm"
          variant="link"
          title="View Groups"
          @click="
            showModalGroups({
              name: `${data.item.client.fname} ${data.item.client.lname}`,
              groups: data.item.client.groups,
            })
          "
          v-b-tooltip.hover
          ><b-icon-diagram3></b-icon-diagram3
        ></b-btn>
      </template>

      <!-- FULL NAME NAME -->
      <template #cell(fullname)="data">
        <b-btn
          size="sm"
          variant="link"
          title="View Profile"
          :to="{ name: 'ClientDetails', params: { id: data.item.id } }"
          v-b-tooltip.hover
          >{{ data.item.fname }} {{ data.item.lname }}</b-btn
        >
      </template>

      <!-- EMAIL -->
      <template #cell(clientEmail)="data">
        <b-btn
          v-if="data.item.client && data.item.client.email"
          size="sm"
          variant="link"
          :title="$t('label.composeAnEmail')"
          :href="'mailto:' + data.item.client.email"
          v-b-tooltip.hover
          >{{ data.item.client.email }}</b-btn
        >
      </template>

      <!-- PHONE -->
      <template #cell(phone)="data">
        {{ data.value | formatPhoneNumber }}
      </template>

      <!-- FRANCHISE CODE -->
      <template #cell(franchiseCode)="data">
        <b-btn
          v-if="parseInt(data.value)"
          size="sm"
          variant="link"
          title="View Franchise"
          @click="ModalFranchiseShow(data.value)"
          v-b-tooltip.hover
          >{{ data.value }}</b-btn
        >
      </template>

      <!-- CREATED -->
      <template #cell(date)="data">
        {{ $moment(data.value).format('MM/DD/YYYY') }}
      </template>

      <!-- LAST UPDATED -->
      <template #cell(lastUpdated)="data">
        {{ $moment(data.value).format('MM/DD/YYYY') }}
      </template>

      <!-- STATUS -->
      <template #cell(status)="data">
        {{ data.item.status ? data.item.status.label : '' }}
      </template>
    </data-table>

    <b-row class="d-print-none">
      <b-col cols="12" lg="3">
        <!-- ACTION FILTERS -->
        <b-input-group prepend="With Selected: " class="my-3">
          <b-form-select
            v-model="selectedAction"
            :options="[
              { value: null, text: '-- Select Action --' },
              { value: 'merge', text: 'Merge' },
            ]"
            @change="onSelectedAction"
          ></b-form-select>
        </b-input-group>
      </b-col>
    </b-row>

    <b-modal ref="client-profile" size="xl" ok-only @hide="onHideClientProfile">
      <template v-if="viewClientProfile" #modal-title>
        {{ viewClientProfile.client.fname }} {{ viewClientProfile.client.lname }}
      </template>
      <ClientProfile
        v-if="viewClientProfile"
        :theFranchiseClient="viewClientProfile"
        no-header
        no-footer
      />
    </b-modal>

    <b-modal
      ref="client-appt"
      size="xl"
      ok-only
      @hide="onHideApptForm"
      no-close-on-backdrop
      hide-footer
    >
      <template v-if="apptForm.type" #modal-title
        >New {{ $t('common.' + apptForm.type) }}</template
      >
      <ClientAppointmentForm
        :client="apptForm.client"
        :type="apptForm.type"
        @complete="$refs['client-appt'].hide()"
        @cancel="$refs['client-appt'].hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../components/DataTable.vue'
import { constants } from '../../shared/constants'
import ClientProfile from './~ClientProfile.vue'
import ClientAppointmentForm from './~ClientAppointmentForm.vue'

export default {
  name: 'ClienList',
  components: { DataTable, ClientProfile, ClientAppointmentForm },
  data() {
    return {
      apptForm: {
        type: null,
        client: null,
      },
      filterLabel: 'Name / Zip / Email',
      selectedAction: null,
      customItems: {
        fullName: {
          key: ['client.fname', 'client.lname'],
          label: 'Name',
          sortable: true,
        }, // @TODO
      },
      defaultFields: [
        { key: 'action', label: '', sortable: false },
        { key: 'client.id', label: 'ID', sortable: true },
        { key: 'clientGroups', label: 'Groups', sortable: false },
        { key: 'client.fname', label: 'First Name', sortable: true },
        { key: 'client.lname', label: 'Last Name', sortable: true },
        { key: 'client.zipCode', label: 'Zip Code', sortable: false },
        { key: 'client.phone', label: 'Phone', sortable: false },
        { key: 'clientEmail', label: 'Email', sortable: false },
        { key: 'date', label: 'Added', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      client: null,
      status: null,
      name: null,
      fname: null,
      lname: null,
      zipCode: null,
      franchiseCode: null,
      showClientProfileModal: false,
      viewClientProfile: null,
    }
  },
  computed: {
    ...mapGetters(['clientStatusOptions']),
    uri() {
      switch (this.currentUser.roles[0]) {
        case constants.USER_ROLE_EC:
        case constants.USER_ROLE_TUTOR:
          return constants.API_URI_EMP_CLIENTS
        case constants.USER_ROLE_ADMIN:
          return constants.API_URI_FRANCHISE_CLIENTS
        default:
          return constants.API_URI_CLIENTS
      }
    },
    uriParams() {
      const params = {
        // status: this.status,
        'client.id': this.client,
        'exists[client]': true, // only return results containing client data
        'client.fname': this.fname,
        'client.lname': this.lname,
        'client.zipCode': this.zipCode,
      }
      if (this.status) {
        params.status = this.status
      }
      if (this.isStaff) {
        params['emp.id'] = this.currentUser.id
      } else if (this.currentFranchiseCode) {
        params.franchiseCode = this.currentFranchiseCode
      } else {
        params.franchiseCode = this.franchiseCode
      }
      return params
    },
    fields() {
      const fields = this.defaultFields
      if (!this.currentFranchiseCode) {
        fields.push({ key: 'franchiseCode', label: 'Franchise', sortable: true })
      }
      return fields
    },
  },
  watch: {
    status(val) {
      this.setStorageItem('status', val)
    },
  },
  created() {
    // assign current franchise, if any
    this.franchiseCode = this.currentFranchiseCode
    this.getClientStatusOptions()
  },
  mounted() {
    // check for previously selected status
    this.status = this.getStorageItem('status', null)
  },
  methods: {
    ...mapActions([
      'getClientStatusOptions',
      'getFranchise',
      'resetFranchise',
      'showModalGroups',
    ]),
    onNewAppt(type, client) {
      this.apptForm.type = type
      this.apptForm.client = client
      this.$refs['client-appt'].show()
    },
    onViewClientProfile(client) {
      this.viewClientProfile = client
      this.$refs['client-profile'].show()
      // this.showClientProfileModal = true
    },
    onHideClientProfile() {
      this.$refs['client-profile'].hide()
      // this.showClientProfileModal = null
      this.viewClientProfile = null
    },
    onHideApptForm() {
      this.$refs['client-appt'].hide()
      this.apptForm = {
        type: null,
        client: null,
      }
    },
    onSelectedAction(action) {
      if (action)
        this.$bvModal.msgBoxOk('The selected action will be taken').then((ok) => {
          if (ok) this.selectedAction = null
        })
    },
    // onShowGroupDetails (client) {
    //   const title = `${client.fname} ${client.lname}'s Group(s)`
    //   const content = client.groups.map(e => {
    //     return `<a href="javascript:;" class="btn btn-info btn-block">${e.group.label
    //       ? `${e.group.type.label}: ${e.group.label}`
    //       : e.group.type.label}</a>`
    //   }).join('<br />')
    //   this.$store.dispatch('addModal', { title, content, size: 'sm', bodyClass: 'text-center' })
    // }
  },
}
</script>
