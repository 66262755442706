<template>
  <b-form ref="formClientAppt" v-if="client" @submit.prevent="onSubmit()">
    <!-- APPT TYPE -->
    <b-form-group
      v-if="!type"
      label="Appointment Type"
      label-for="appointment-type"
      description="Select the type of appointment"
    >
      <b-form-select id="appointment-type" v-model="type" :options="appointmentTypes">
        <template #first>
          <b-form-select-option :value="null" disabled
            >Select Appointment Type</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>

    <b-row>
      <b-col cols="12" lg="6">
        <!-- CLIENT -->
        <fieldset>
          <legend>Client</legend>
          <p>
            {{ `${client.fname} ${client.lname}` }}
          </p>
        </fieldset>
      </b-col>
      <b-col cols="12" lg="6">
        <!-- LOCATION -->
        <fieldset>
          <legend>Location</legend>
          <p v-if="!showLocationFields">
            {{
              `${appt.street1} ${appt.street2} ${appt.city} ${appt.state} ${appt.zipCode}`
            }}
            <a href="#" @click.prevent="showLocationFields = !showLocationFields"
              ><i class="fa fa-pencil-alt" aria-hidden="true"></i
            ></a>
          </p>
          <template v-if="showLocationFields">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Street Address"
                  label-for="appt-street1"
                  label-sr-only
                >
                  <b-form-input
                    id="appt-street1"
                    v-model="appt.street1"
                    placeholder="Street Address"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Apt/Suite" label-for="appt-street2" label-sr-only>
                  <b-form-input
                    id="appt-street2"
                    v-model="appt.street2"
                    placeholder="Apt"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="4">
                <b-form-group label="City" label-for="city" label-sr-only>
                  <b-form-input id="city" v-model="appt.city" placeholder="City" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="State" label-for="appt-state" label-sr-only>
                  <BaseFormSelectState
                    v-model="appt.state"
                    id="appt-state"
                    :country="client.country"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Zip Code" label-for="appt-zipCode" label-sr-only>
                  <b-form-input
                    id="appt-zipCode"
                    v-model="appt.zipCode"
                    placeholder="Zip Code"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-button variant="success" class="mb-3" @click="showLocationFields = false"
              >Save Location</b-button
            >
          </template>
        </fieldset>
      </b-col>
    </b-row>

    <!-- GROUP / SUBJECT(S) / DESCRIPTION -->
    <!-- TODO: auto-fill based on staff - assign staff to billing (containing group & subjects) - ? make staff-assigned billing the child of a master billing record -->
    <fieldset>
      <legend>Group / Subject(s) / Description</legend>
      <template v-if="!franchiseSubscriptions.length">
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Group" label-for="appointment-group" label-sr-only>
              <FormApptServiceGroup
                v-model="appt.serviceGroup"
                id="appointment-group"
                :franchiseCode="appt.franchiseCode"
                request-service-groups
                hide-data
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Group" label-for="appointment-group" label-sr-only>
              <FormApptServices
                v-model="appt.services"
                id="appointment-group"
                :franchiseCode="appt.franchiseCode"
                :serviceGroup="appt.serviceGroup"
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="Academic Description"
          label-for="full-description"
          label-sr-only
        >
          <b-form-textarea
            id="full-description"
            v-model="appt.fullDescription"
            placeholder="Academic Description"
          ></b-form-textarea>
        </b-form-group>
      </template>
      <p v-else-if="franchiseSubscriptions.length">
        {{ franchiseSubscriptions[0].serviceGroup.customName }} -
        {{ franchiseSubscriptions[0].service.name }}
      </p>
      <p>@TODO: add a description prop</p>
    </fieldset>

    <!-- APPT DATE/TIME -->
    <fieldset>
      <legend>Date / Time</legend>
      <b-form-group
        label="Date / Time"
        label-for="appointment-date"
        description="Select the date and time of the appointment"
        label-sr-only
      >
        <DateTime id="appointment-date" v-model="apptDateTime" label-sr-only />
      </b-form-group>
    </fieldset>
    <!-- STAFF -->
    <fieldset v-if="!isStaff">
      <legend>Consultant / Tutor</legend>
      <b-row>
        <b-col>
          <b-form-group
            label="Staff"
            label-for="appointment-staff"
            description="Select the staff member for the appointment"
            label-sr-only
          >
            <FormSelectFranchiseStaff
              id="appointment-staff"
              v-model="staff['@id']"
              :franchiseCode="appt.franchiseCode"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-input-group prepend="$">
              <b-form-input v-model="appt.payRate" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </fieldset>

    <!-- FOOTER -->
    <footer class="mt-2">
      <BaseFormButtons
        label-cancel="Cancel"
        label-save="Submit"
        @form-button-cancel="onCancel"
        @form-button-submit="$refs.formClientAppt.submit()"
        no-reset
      />
      <!-- b-button variant="warning" @click="onCancel()">Cancel</b-button>
      <b-button variant="success" class="float-right" type="submit">Save</b-button -->
    </footer>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DateTime from '../../components/DateTime.vue'
import FormApptServiceGroup from '../../components/FormApptServiceGroup.vue'
import FormApptServices from '../../components/FormApptServices.vue'
import FormSelectFranchiseStaff from '../../components/FormSelectFranchiseStaff.vue'
export default {
  name: 'ClientAppointmentForm',
  components: {
    DateTime,
    FormApptServiceGroup,
    FormApptServices,
    FormSelectFranchiseStaff,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showLocationFields: false,
    apptDateTime: null,
    appt: {
      rep: null,
      consultation: null,
      statusLabel: null,
      // apptType: null,
      franchise: null,
      franchiseCode: null,
      client: null,
      payRate: 0,
      // location
      street1: null,
      street2: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      // date/time
      startDate: null,
      startTime: null,
      duration: null,
      schedules: [],
      // service
      serviceGroup: null,
      services: [],
      fullDescription: null,
      // other
      notes: null,
      subscription: null,
    },
    staff: null,
  }),
  computed: {
    ...mapGetters(['franchiseSubscriptions']),
    appointmentTypes() {
      return [
        { value: 'consultation', text: 'Initial Consultation' },
        { value: 'clientVisit', text: 'Session' },
        { value: '3', text: 'Other' },
      ]
    },
  },
  mounted() {
    // this.apptType = this.type
    this.apptDateTime = this.$moment().add(1, 'day').format('YYYY-MM-DDT09:00:00')
    this.appt.client = this.client['@id']
    this.appt.consultation = this.type === 'consultation' ? true : false
    this.setLocationFromClient()
    this.appt.franchise = this.currentFranchise['@id']
    this.appt.franchiseCode = this.currentFranchise.franchiseCode
    if (this.isStaff) {
      this.staff = this.currentUser
      this.appt.payRate = this.currentUser.payRate
    }
  },
  watch: {
    apptDateTime: {
      handler: function (val) {
        const nv = val.split('T')
        this.appt.startDate = nv[0]
        this.appt.starttime = nv[1]
      },
      deep: true,
    },
    staff: {
      handler: async function (val) {
        await this.getFranchiseSubscriptions({
          client: this.client.id,
          emp: val.id,
        })
        this.appt.serviceGroup = this.franchiseSubscriptions[0].serviceGroup['@id']
        this.appt.services = [this.franchiseSubscriptions[0].service['@id']]
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['getFranchiseSubscriptions']),
    checkSubscriptions() {},
    setLocationFromClient() {
      this.appt.street1 = this.client.street1
      this.appt.street2 = this.client.street2
      this.appt.city = this.client.city
      this.appt.state = this.client.state
      this.appt.zipCode = this.client.zipCode
      this.appt.country = this.client.country
    },
    onSubmit() {
      this.$emit('complete', this.appt)
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
}
</script>
