var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button-group',{staticClass:"mb-2 w-100",attrs:{"size":"sm"}},[(_vm.$can('read', 'Enrollments'))?_c('b-button',{attrs:{"to":{
        name: 'ClientAppointments',
        params: { type: 'enrollment' },
        query: { cid: _vm.theFranchiseClient.client.id },
      }}},[_vm._v("Enrollments")]):_vm._e(),(_vm.$can('read', 'Sessions'))?_c('b-button',{attrs:{"to":{
        name: 'ClientAppointments',
        params: { type: 'session' },
        query: { cid: _vm.theFranchiseClient.client.id },
      }}},[_vm._v("Sessions")]):_vm._e(),(_vm.$can('read', 'Billing'))?_c('b-button',{attrs:{"to":{ name: 'BillingList', query: { cid: _vm.theFranchiseClient.client.id } }}},[_vm._v("Billing")]):_vm._e(),(_vm.$can('manage', 'Sessions'))?_c('b-button',[_vm._v("New Session")]):_vm._e()],1),_c('b-card-group',{attrs:{"deck":""}},[_c('CardClientDetails',{attrs:{"theFranchiseClient":_vm.theFranchiseClient,"noFooter":_vm.noFooter}}),_c('CardClientEmployees',{attrs:{"theFranchiseClient":_vm.theFranchiseClient,"noFooter":_vm.noFooter}}),_c('CardClientContactDetails',{attrs:{"theFranchiseClient":_vm.theFranchiseClient,"noFooter":_vm.noFooter}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }