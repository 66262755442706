<template>
  <b-form-select
    id="select-franchise-staff"
    v-model="selected"
    :options="staffOptions"
    :required="required"
  >
    <template #first>
      <b-form-select-option :value="null">Select Staff Member</b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FormSelectFranchiseStaff',
  props: {
    franchiseCode: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'tutor',
    },
    status: {
      type: Number,
      default: 2,
    },
    required: {
      type: Boolean,
      default: false,
    },
    altOpt: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    ...mapGetters(['franchiseStaff']),
    staffOptions() {
      if (!this.franchiseStaff.length) {
        return [
          {
            value: null,
            text: 'No Staff Members Loaded',
          },
        ]
      }
      const ret = this.franchiseStaff.map((s) => {
        if (!s.employee) {
          return
        }
        return {
          value: s.employee['@id'],
          text: `${s.employee.profile.fname} ${s.employee.profile.lname}`,
        }
      })
      if (this.altOpt) {
        ret.push(this.altOpt)
      }
      return ret
    },
  },
  mounted() {
    this.doGetFranchiseStaff()
  },
  watch: {
    franchiseCode() {
      this.doGetFranchiseStaff()
    },
    selected() {
      this.$emit('input', this.selected)
    },
  },
  methods: {
    ...mapActions(['getFranchiseStaff']),
    doGetFranchiseStaff() {
      this.getFranchiseStaff({
        franchiseCode: this.franchiseCode,
        type: this.type,
        status: this.status,
      })
    },
  },
}
</script>
