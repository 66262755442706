<template>
  <div>
    <b-button-group size="sm" class="mb-2 w-100">
      <b-button
        v-if="$can('read', 'Enrollments')"
        :to="{
          name: 'ClientAppointments',
          params: { type: 'enrollment' },
          query: { cid: theFranchiseClient.client.id },
        }"
        >Enrollments</b-button
      >
      <b-button
        v-if="$can('read', 'Sessions')"
        :to="{
          name: 'ClientAppointments',
          params: { type: 'session' },
          query: { cid: theFranchiseClient.client.id },
        }"
        >Sessions</b-button
      >
      <b-button
        v-if="$can('read', 'Billing')"
        :to="{ name: 'BillingList', query: { cid: theFranchiseClient.client.id } }"
        >Billing</b-button
      >
      <b-button v-if="$can('manage', 'Sessions')">New Session</b-button>
    </b-button-group>
    <b-card-group deck>
      <CardClientDetails :theFranchiseClient="theFranchiseClient" :noFooter="noFooter" />
      <CardClientEmployees
        :theFranchiseClient="theFranchiseClient"
        :noFooter="noFooter"
      />
      <CardClientContactDetails
        :theFranchiseClient="theFranchiseClient"
        :noFooter="noFooter"
      />
    </b-card-group>
  </div>
</template>

<script>
import CardClientDetails from './-CardClientDetails.vue'
import CardClientEmployees from './-CardClientEmployees.vue'
import CardClientContactDetails from './-CardClientContactDetails.vue'
import clientProfileMixins from './clientProfileMixins'

export default {
  name: 'ClientDetails',
  props: {
    noHeader: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
  },
  components: { CardClientDetails, CardClientEmployees, CardClientContactDetails },
  mixins: [clientProfileMixins],
}
</script>
