<template>
  <b-card
    v-if="clientData.id"
    header-tag="header"
    :header-bg-variant="bgVariantHeader"
    :header-text-variant="textVariantHeader"
    footer-tag="footer"
  >
    <template v-if="!noHeader" #header>General Details</template>
    franchise: {{ franchiseClientData.franchiseCode }}<br />
    status: {{ franchiseClientData.status ? franchiseClientData.status.label : '' }}<br />
    <template v-if="!noFooter" #footer>Some Footer Actions...</template>
  </b-card>
</template>

<script>
import mixins from './clientProfileMixins'

export default {
  props: {
    noHeader: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
  },
  name: 'ClientDetailsCard',
  mixins: [mixins],
  computed: {},
}
</script>

<style lang="scss">
address {
  @extend .py-2 !optional;
}
</style>
